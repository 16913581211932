import React, { useEffect } from "react"
import { graphql } from "gatsby"
import { Disqus } from "gatsby-plugin-disqus"
import { Helmet } from "react-helmet"

import SEO from "../components/seo"

import * as loadScript from "simple-load-script"
import "babel-polyfill"

import {
  Header,
  Preloader,
  Sidenav,
  BlogPost
} from "../components"

const BlogPage = ({
  location,
  data: {
    prismicSidenav,
    prismicPost
  },
  pageContext: { locale },
}) => {
  useEffect(() => {
    ; (async function loadScripsAsync() {
      await loadScript("/scripts/jquery.min.js", { inBody: true })
      await loadScript("/scripts/bootstrap.min.js", { inBody: true })
      await loadScript("/scripts/plugins.min.js", { inBody: true })
      await loadScript("/scripts/main-scripts.js", { inBody: true })
    })()
  }, [])

  return (
    <>
      <SEO
        title={prismicPost.data.title.text}
        pathname={location.pathname}
      />
      <Helmet>
        <style type="text/css">{`
          #pp-nav {
            display: none;
          }
        `}</style>
      </Helmet>
      <Preloader />
      <Header withoutMenu />
      <Sidenav data={prismicSidenav.data} locale={locale} />
      <div id="site-wrapper">
        <BlogPost
          locale={locale}
          data={prismicPost.data}
          alternateLng={prismicPost.alternate_languages[0]}
          disqus={<Disqus
            config={{
              /* Replace PAGE_URL with your post's canonical URL variable */
              url: location.href,
              /* Replace PAGE_IDENTIFIER with your page's unique identifier variable */
              identifier: prismicPost.id,
              /* Replace PAGE_TITLE with the title of the page */
              title: prismicPost.data.title.text,
            }}
          />}
        />
      </div>

    </>
  )
}

export const pageQuery = graphql`
  query BlogPostQuery($locale: String!, $slug: String!) {
    prismicSidenav(lang: { eq: $locale }) {
      data {
        testimonials {
          text
        }
        services {
          text
        }
        resume {
          text
        }
        home {
          text
        }
        contact {
          text
        }
        blog {
          text
        }
        about {
          text
        }
      }
    }

    prismicPost(uid: {eq: $slug}) {
      id
      data {
        date
        content {
          html
        }
        title {
          text
        }
        image_header {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1065, height: 150)
            }
          }
        }
      }
      alternate_languages {
        uid
        lang
      }
      id
    }
  }
`

export default BlogPage
